import { Component, AfterViewInit, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { AccountClientModel, AccountMessageRequestTypeEnum, AccountModel, CloseAccountRequestDetailModel, CloseAccountRequestsClient, ContributionRequestDetailModel, ContributionRequestsClient, DistributionRequestDetailModel, DistributionRequestsClient, FileModel, InvestmentAccountsClient, OrionServiceRequestModel2, OrionServiceRequestsClient, PlanningDeskAccountModel, PlanningDeskRequestDetailModel, PlanningDeskRequestsClient, ReallocationRequestDetailModel, ReallocationRequestsClient, RequestModel, RequestNoteModel, SleeveModel, TradeModel } from 'src/app/core/clients/generated/client';
import { MessageAndFileModel, RequestTrackerTypeEnum } from '../../models/request-tracker-models';
import { fadeIn } from 'src/app/shared/constants/animations';
import { FileMessageUploadModalAttachedRequest, FileMessageUploadModalModel } from '../../../files-messages/models/files-messages.model';
import { Account } from '../../../accounts/models/account.models';
import { formatPercent } from '@angular/common';

@Component({
  animations: [fadeIn],
  selector: 'app-request-tracker-detail--modal',
  templateUrl: './request-tracker-detail-modal.component.html',
})
export class RequestTrackerDetailModalComponent implements OnInit, AfterViewInit {
  constructor(
    private reallocationRequestsApiService: ReallocationRequestsClient,
    private distributionRequestsApiService: DistributionRequestsClient,
    private closeAccountRequestsApiService: CloseAccountRequestsClient,
    private contributionRequestsApiService: ContributionRequestsClient,
    private planningDeskRequestsApiService: PlanningDeskRequestsClient,
    private orionServiceRequestsApiService: OrionServiceRequestsClient,
    private snackbarService: SnackbarService,
    private investmentAccountApi: InvestmentAccountsClient,
    public dialogRef: MatDialogRef<RequestTrackerDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequestModel,
  ) { }

  clients: AccountClientModel[] = [];
  clientTitle = RequestTrackerTypeEnum.Clients;
  details = new Map<string, unknown>();
  filePrefix = `api/${this.data.category.replace('-', '').replace(/\s+/g, '-').toLowerCase()}-requests/${this.data.investmentAccountModelRequestId
    || this.data.investmentAccountDistributionRequestId || this.data.portfolioAnalysisRequestId || this.data.orionServiceRequestId}/files/`;
  grossDistributionAmountTitle = RequestTrackerTypeEnum.GrossDistributionAmount;
  grossContributionAmountTitle = RequestTrackerTypeEnum.GrossContributionAmount;
  grossDistributionAmount? = 0.0;
  grossContributionAmount? = 0.0;
  grossSleeveAmount = 0.0;
  note = '';
  files: FileModel[] = [];
  notes: RequestNoteModel[] = [];
  notesAndFiles: MessageAndFileModel[] = [];
  tradeInstructions = '';
  sleeves: SleeveModel[] = [];
  trades: TradeModel[] = [];
  planningDeskAccounts: PlanningDeskAccountModel[] = [];
  sleevesTitle = RequestTrackerTypeEnum.Sleeves;
  tradesTitle = RequestTrackerTypeEnum.Trades;
  accountsTitle = RequestTrackerTypeEnum.Accounts;
  requestTrackerTypeEnum = RequestTrackerTypeEnum;
  message?: FileMessageUploadModalModel;
  statusObj = {
    Show: false,
    Status: '',
    StatusDate: '',
    StatusNote: '',
    StatusId: 0,
  };

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    const width = '166rem';
    const height = '84rem';
    this.dialogRef.updateSize(width, height);
  }

  getData(): void {
    this.note = '';
    this.tradeInstructions = '';
    this.clients = [];
    this.details.clear();
    this.initFileMessageComponent(this.data);

    if (this.data.category === RequestTrackerTypeEnum.Reallocation) {
      if (this.data.investmentAccountModelRequestId != null)
        this.reallocationRequestsApiService.getReallocationRequestDetail(this.data.investmentAccountModelRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category === RequestTrackerTypeEnum.CloseAccount) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.closeAccountRequestsApiService.getCloseAccountRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category === RequestTrackerTypeEnum.Distribution) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.distributionRequestsApiService.getDistributionRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category === RequestTrackerTypeEnum.Contribution) {
      if (this.data.investmentAccountDistributionRequestId != null)
        this.contributionRequestsApiService.getContributionRequestDetail(this.data.investmentAccountDistributionRequestId).subscribe({
          next: (details) => {
            this.setRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category === RequestTrackerTypeEnum.PlanningDesk) {
      if (this.data.portfolioAnalysisRequestId != null)
        this.planningDeskRequestsApiService.getPlanningDeskRequestDetail(this.data.portfolioAnalysisRequestId).subscribe({
          next: (details) => {
            this.setPlanningDeskDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    } else if (this.data.category === RequestTrackerTypeEnum.OrionServiceRequest) {
      if (this.data.orionServiceRequestId != null)
        this.orionServiceRequestsApiService.getOrionServiceRequestDetail(this.data.orionServiceRequestId).subscribe({
          next: (details) => {
            this.setOrionServiceRequestDetailObject(details);
          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving agent information', ToastClassEnum.warning);
          }
        });
    }
  }

  private initFileMessageComponent(model: RequestModel): void {

    const invOnBehalfOfRequest: FileMessageUploadModalAttachedRequest = {
      requestId: model.investmentAccountDistributionRequestId ? model.investmentAccountDistributionRequestId : model.investmentAccountModelRequestId ? model.investmentAccountModelRequestId : model.orionServiceRequestId ? model.orionServiceRequestId : model.portfolioAnalysisRequestId ? model.portfolioAnalysisRequestId : undefined,

      requestType: model.investmentAccountDistributionRequestId ? AccountMessageRequestTypeEnum.Distribution : model.investmentAccountModelRequestId ? AccountMessageRequestTypeEnum.ModelAllocation : model.orionServiceRequestId ? AccountMessageRequestTypeEnum.OrionServiceRequest : model.portfolioAnalysisRequestId ? AccountMessageRequestTypeEnum.PlanningDesk : undefined,

      requestTitle: model.category + (model.subCategory ? ' - ' + model.subCategory : '')
    };

    if (model.investmentAccountId != null) {
      this.investmentAccountApi.getInvestmentAccount(model.investmentAccountId as number).subscribe({
        next: apiAccount => {
          const invAccount = this.convertInvestmentAccountToAccount(apiAccount);

          this.message = {
            account: invAccount,
            onBehalfOfRequest: invOnBehalfOfRequest,
          } as FileMessageUploadModalModel;
        },
      });
    } else {
      this.message = {
        account: undefined,
        onBehalfOfRequest: invOnBehalfOfRequest,
      } as FileMessageUploadModalModel;
    }
  }

  convertInvestmentAccountToAccount(account: AccountModel): Account {
    return {
      Advisors: account.advisors.map(a => {
        return {
          AdvisorId: a.agentID,
          Crd: a.crd,
          FirstName: a.firstName,
          LastName: a.lastName,
        };
      }),
      Clients: account.clients.map(c => {
        return {
          Id: c.id,
          FirstName: c.firstName,
          LastName: c.lastName,
        };
      }),
      Type: account.investmentAccountType,
      Model: account.investmentAccountModel,
      Custodian: account.custodian,
      CustodianId: account.custodianID as number,
      Value: account.accountValue as number,
      CashValue: account.cashValue as number,
      OpenedDate: account.openedOn as Date,
      Fee: RequestTrackerDetailModalComponent.calculateAccountFee(account),
      Status: account.investmentAccountStatus,
      IsInvestmentAccount: true,
      AccountNumber: account.accountNumber,
      Carrier: null,
      Product: null,
      InsuranceAccountId: null,
      InvestmentAccountId: account.investmentAccountID,
      Sleeves: account.sleeves,
      ShowSleeves: false,
      TDSchwabAccountNumbers: account.tdSchwabAccountNumbers,
    };
  }

  private static calculateAccountFee(account: AccountModel): string {
    if (account.totalFee === 0) return '---';
    if (!account.sleeves.length) return formatPercent(account.totalFee ?? 0, 'en-US', '1.2');
    if (account.sleeves.every(a => a.accountTotalFee === account.sleeves[0].accountTotalFee)) // check if all sleeves have same fee
      return formatPercent(account.sleeves[0].accountTotalFee, 'en-US', '1.2');
    return 'Multiple';
  }

  getTotalSleeves(): void {
    this.sleeves.forEach(element => {
      this.grossSleeveAmount += element.amount;
    });
  }

  closeDialogAndSendMessage(): void {
    this.dialogRef.close({ openMessage: true });
  }

  convertFileToMessagesAndFiles(file: FileModel): MessageAndFileModel {
    return {
      CreatedByUser: file.createdByUser ?? null,
      CreatedByUserName: file.createdByUserName ?? null,
      CreatedOn: file.createdOn ?? null,
      ExternalStatus: null,
      Note: null,
      FileName: file.fileName,
      FileID: file.fileID as unknown as string,
      StatusID: null,
    };
  }

  convertNoteToMessagesAndFiles(message: RequestNoteModel): MessageAndFileModel {
    return {
      CreatedByUser: message.createdByUser ?? null,
      CreatedByUserName: message.createdByUserName ?? null,
      CreatedOn: message.createdOn ?? null,
      ExternalStatus: message.externalStatus ?? null,
      Note: message.note ?? null,
      FileName: null,
      FileID: null,
      StatusID: message.statusID as number,
    };
  }

  sortNotesAndFiles(property: string, order: 'asc' | 'desc' = 'asc'): MessageAndFileModel[] {
    return this.notesAndFiles.sort((a, b) => {
      const aValue = a.CreatedOn;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const bValue = b.CreatedOn;

      if (aValue !== null && bValue !== null && aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      } else if (aValue !== null && bValue !== null && aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  setRequestDetailObject(detail: ContributionRequestDetailModel | DistributionRequestDetailModel | CloseAccountRequestDetailModel | ReallocationRequestDetailModel): void {
    //console.log(detail);
    this.files = detail.files;
    this.notes = detail.noteHistory;

    const convertedFiles = this.files.map(this.convertFileToMessagesAndFiles);
    const convertedNotes = this.notes.map(this.convertNoteToMessagesAndFiles);
    this.notesAndFiles = convertedFiles.concat(convertedNotes);
    this.notesAndFiles = this.sortNotesAndFiles('CreatedOn', 'desc');

    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate = new Date(detail.statusDate).toLocaleDateString();
    this.statusObj.StatusNote = detail.statusNote;
    this.statusObj.StatusId = detail.statusId;

    this.details.set('Clients', '');
    this.details.set('Account #', detail.investmentAccountNumber);
    this.details.set('Account Type', detail.investmentAccountType);
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());

    if (this.data.category.includes(RequestTrackerTypeEnum.Reallocation)) {
      const m = detail as ReallocationRequestDetailModel;
      this.sleeves = m.sleeves;
      this.tradeInstructions = m.tradeInstructions;
      this.trades = m.trades;
    } else if (this.data.category.includes(RequestTrackerTypeEnum.Distribution)) {
      const d = detail as DistributionRequestDetailModel;
      this.details.set('Federal Tax Withholding', d.federalTax ? d.federalTax + '%' : 'N/A');
      this.details.set('State Tax Withholding', d.stateTax ? d.stateTax + '%' : 'N/A');
      this.details.set('Frequency', d.frequency);
      this.details.set('Method', d.method);
      this.details.set('Gross Distribution Amount', '');
      this.details.set('Begins On', d.beginsOn ? new Date(d.beginsOn).toLocaleDateString() : 'N/A');
      this.sleeves = d.sleeves;
      this.tradeInstructions = d.tradeInstructions;
      this.trades = d.trades;
      this.grossDistributionAmount = d.grossAmount;
    } else if (this.data.category.includes(RequestTrackerTypeEnum.CloseAccount)) {
      const d = detail as CloseAccountRequestDetailModel;
      this.details.set('Federal Tax', d.federalTax ? d.federalTax + '%' : 'N/A');
      this.details.set('State Tax', d.stateTax ? d.stateTax + '%' : 'N/A');
      this.details.set('Distribution Method', d.method);
      this.details.set('Bill As Of Close Date', d.isCloseDateBilled ? 'Yes' : 'No');
    } else if (this.data.category.includes(RequestTrackerTypeEnum.Contribution)) {
      const d = detail as ContributionRequestDetailModel;
      this.details.set('Frequency', d.frequency);
      this.details.set('Method', d.method);
      this.details.set('Gross Contribution Amount', '');
      this.details.set('Begins On', d.beginsOn ? new Date(d.beginsOn).toLocaleDateString() : 'N/A');
      this.sleeves = d.sleeves;
      this.tradeInstructions = d.tradeInstructions;
      this.grossContributionAmount = d.grossAmount;
    }
    this.clients = detail.clients;
    this.note = detail.notes;
    if (this.sleeves.length > 0) {
      this.details.set('Allocations', '');
      this.getTotalSleeves();
    }
    if (this.trades.length > 0) {
      this.details.set('Trades', '');
    }
  }

  setPlanningDeskDetailObject(detail: PlanningDeskRequestDetailModel): void {
    console.log(detail);
    this.files = detail.files;
    this.notes = detail.noteHistory;

    const convertedFiles = this.files.map(this.convertFileToMessagesAndFiles);
    const convertedNotes = this.notes.map(this.convertNoteToMessagesAndFiles);
    this.notesAndFiles = convertedFiles.concat(convertedNotes);
    this.notesAndFiles = this.sortNotesAndFiles('CreatedOn', 'desc');

    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate = detail.statusDate ? new Date(detail.statusDate).toLocaleDateString() : 'N/A';
    this.statusObj.StatusId = detail.statusId;
    this.statusObj.StatusNote = detail.statusNote;

    this.details.set('Client', detail.client);
    this.details.set('Request Type', detail.requestType);
    this.details.set('Next Appointment', detail.nextAppointment ? new Date(detail.nextAppointment).toLocaleDateString() : 'N/A');
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());
    this.details.set('Updated On', detail.statusDate ? new Date(detail.statusDate).toLocaleDateString() : 'N/A');
    this.details.set('Status', detail.status);

    this.note = detail.notes;
    this.planningDeskAccounts = detail.accounts;
    if (this.planningDeskAccounts.length > 0) {
      this.details.set('Accounts', '');
      this.planningDeskAccounts.forEach(element => {
        element.clientFee = element.clientFee / 100;
      });
    }
  }

  setOrionServiceRequestDetailObject(detail: OrionServiceRequestModel2): void {
    this.files = detail.files;
    this.notes = detail.noteHistory;

    const convertedFiles = this.files.map(this.convertFileToMessagesAndFiles);
    const convertedNotes = this.notes.map(this.convertNoteToMessagesAndFiles);
    this.notesAndFiles = convertedFiles.concat(convertedNotes);
    this.notesAndFiles = this.sortNotesAndFiles('CreatedOn', 'desc');

    this.statusObj.Status = detail.status;
    this.statusObj.StatusDate = new Date(detail.statusDate).toLocaleDateString();
    this.statusObj.StatusNote = detail.statusNote;
    this.statusObj.StatusId = detail.statusId;

    this.details.set('Clients', '');
    this.details.set('Account #', detail.investmentAccountNumber);
    this.details.set('Account Type', detail.investmentAccountType);
    this.details.set('Submitted On', new Date(detail.submittedDate).toLocaleDateString());
    this.clients = detail.clients;

    if (detail.requestDetails.length) {

      const requestType = detail.requestDetails[0].requestType;
      this.details.set(requestType + ' Type', detail.requestDetails[0].requestSubType);

      if (detail.requestDetails[0].contributionAmount != null) {
        this.details.set('Gross Contribution Amount', '');
        this.grossContributionAmount = detail.requestDetails[0].contributionAmount;
      }

      if (detail.requestDetails[0].distributionAmount != null) {
        this.details.set('Gross Distribution Amount', '');
        this.grossDistributionAmount = detail.requestDetails[0].distributionAmount;
      }

      if (detail.requestDetails[0].setupOption != null) {
        this.details.set('Setup Option', detail.requestDetails[0].setupOption);
      }

      if (detail.requestDetails[0].frequency != null) {
        this.details.set('Frequency', detail.requestDetails[0].frequency);
      }

      if (detail.requestDetails[0].startDate != null) {
        this.details.set('Start Date', new Date(detail.requestDetails[0].startDate).toLocaleDateString());
      }

      if (detail.requestComments.length) {
        detail.requestComments.forEach((comment: { note: string; }) => {
          this.note += comment.note + '\r\n\r\n';
        });
      }
    }
  }

  sortNull(): number {
    return 0;
  }
}
