<section @fadeIn class="flex flex-col w-full h-full pt-15">
  <!-- For now, don't show ability to reply w/ message or file for Planning Desk requests 3/12/25 -->
  @if (this.fileMessageData && this.fileMessageData.onBehalfOfRequest?.requestType !== requestTypeEnum.PlanningDesk && statusId !== 5 && statusId !== 6)
  {
  <form [formGroup]="advisorMessageFormGroup" (ngSubmit)="onSubmit()">
    <div class="w-full">
      <section>
        <div class="flex flex-col flex-none">
          <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Message</label>
          <textarea class="textarea" formControlName="message" cols="30" rows="3"
            placeholder="Enter your message here..."></textarea>
        </div>
        <div class="flex flex-col flex-none mt-10">
          <label class="mb-5 text-sm font-bold text-gray-dark required-label" for="message">New Upload</label>
          <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>

        </div>
        <div class="flex flex-row align-middle justify-end">
          @if ((advisorMessageFormGroup.controls.message.touched &&
          advisorMessageFormGroup.controls.message.errors) || (advisorMessageFormGroup.controls.files.touched &&
          advisorMessageFormGroup.controls.files.errors)) {
          <span class="w-full mt-5 text-sm text-red">
            @if (advisorMessageFormGroup.controls.files.hasError('required') ||
            advisorMessageFormGroup.controls.message.hasError('required')) {
            <span>A message or upload is required.</span>
            }
          </span>
          }
          <button mat-button type="submit" class="m-10 ml-auto button is-md is-primary"
            [disabled]="isLoading || (this.fileMessageData && !this.selectedAccount)">
            {{isLoading ? "Submitting" : "Submit"}}</button>
        </div>
      </section>
    </div>
  </form>
  }

  <section>
    <label class="mb-5 text-sm font-bold text-gray-dark required-label">Timeline</label>
    <div class="overflow-auto max-h-400">
      @for (n of notesAndFiles; track n) {

      <div
        class="flex flex-col pt-5 pl-10 pr-10 mt-10 rounded bg-gray-lightest border border-solid border-gray-lighter">
        <div class="flex flex-col p-5 mt-5 rounded bg-white">
          @if (n.ExternalStatus) {
          <div class="flex flex-row text-sm">
            <span class="text-orange mr-10">
              <mat-icon class="mi-18">report_problem</mat-icon>
            </span>
            <span class="text-green-dark mr-5">Status updated to: </span>
            <span [ngStyle]="{'color': 
                  n.StatusID === 4 ? '#D71D00' : 
                  n.StatusID === 5 ? '#127161' : 
                  n.StatusID === 6 ? '#FFAF3D' : 
                  n.StatusID === 7 ? '#D71D00' : 
                  n.StatusID === 8 ? '#D71D00' : 
                  n.StatusID === 9 ? '#D71D00' : 
                  '#198447'}"> {{ n.ExternalStatus }}</span>
          </div>
          }
          @if (n.Note) {
          <div class="flex flex-row text-sm whitespace-pre-wrap overflow-wrap-text">
            <!-- @if (!n.ExternalStatus)
            {
              @if (n.CreatedByUserName !== null && n.CreatedByUserName.includes('acm')) {
                <span class="text-gray-dark mr-5">
                  <mat-icon class="mi-18">sticky_note_2</mat-icon>
                </span>
                }
                @if (n.CreatedByUserName !== null && !n.CreatedByUserName.includes('acm')) {
                <span class="text-blue-dark mr-5">
                  <mat-icon class="mi-18">sticky_note_2</mat-icon>
                </span>
                }
            }             -->
            <span class="whitespace-pre-wrap overflow-wrap-text text-sm"
              [ngClass]="{'text-gray-darker' : n.CreatedByUserName !== null && n.CreatedByUserName.includes('acm'), 'text-blue-dark' : n.CreatedByUserName !== null && !n.CreatedByUserName.includes('acm')}">
              {{ n.Note }}</span>
          </div>
          }

          @if (n.FileID !== null && n.FileID !== undefined) {
          <div class="flex flex-row text-sm">
            <span class="text-orange mr-5">
              <mat-icon class="mi-18 text-blue-dark">description</mat-icon>
            </span>
            <span class="text-green-dark mr-10">File Uploaded: </span>
            <a class="flex no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
              [href]="filePrefix + n.FileID.toString()" target="_blank" [attr.aria-label]="'Open ' + n.FileName">
              <span class="text-sm leading-normal">{{n.FileName}}</span>
            </a>
          </div>
          }

        </div>
        <div class="flex flex-row p-5 justify-end text-xs">
          <span class="whitespace-pre-wrap overflow-wrap-text"
            [ngClass]="{'text-gray-darker' : n.CreatedByUserName !== null ? n.CreatedByUserName.includes('acm') : '', 'text-blue-darker' : n.CreatedByUserName !== null && !n.CreatedByUserName.includes('acm')}">
            {{ n.CreatedByUser }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
          <span class="whitespace-pre-wrap overflow-wrap-text text-xs">{{ n.CreatedOn | date: 'MM/dd/yyyy' }} {{
            n.CreatedOn | date: 'shortTime'
            }}</span>

        </div>
      </div>
      }
    </div>
  </section>
</section>