import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RequestTrackerPageComponent } from './pages/request-tracker-page.component';
import { RequestTrackerListComponent } from './components/request-tracker-list/request-tracker-list.component';
import { RequestTrackerDetailModalComponent } from './modals/request-tracker-detail-modal/request-tracker-detail-modal.component';
import { RequestTrackerMessagesAndFilesComponent } from './components/request-tracker-messages-and-files/request-tracker-messages-and-files.component';

@NgModule({
  declarations: [
    RequestTrackerPageComponent,
    RequestTrackerListComponent,
    RequestTrackerDetailModalComponent,
    RequestTrackerMessagesAndFilesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    Map
  ]
})
export class RequestTrackerModule { }
