import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, OnChanges, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, forkJoin, map, Observable, startWith, Subscription, switchMap } from 'rxjs';
import { AccountMessageModel, AccountMessageRequestTypeEnum, AccountSearchListModel, AccountUploadsClient, AgentModel, AgentsClient, FileParameter, InvestmentAccountsClient, PlanningDeskMessageModel } from 'src/app/core/clients/generated/client';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { AccountMessagePostModel, AdvisorMessageFormGroup, FileMessageUploadModalModel, PlanningDeskMessagePostModel, Recipient } from '../../../files-messages/models/files-messages.model';
import { recipientsListData } from '../../../files-messages/constants/files-messages.constants';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { Account } from '../../../accounts/models/account.models';
import { FileDropperComponent } from 'src/app/shared/components/file-dropper/file-dropper.component';
import { MessageAndFileModel } from '../../models/request-tracker-models';

@Component({
  selector: 'app-request-tracker-messages-and-files',
  templateUrl: './request-tracker-messages-and-files.component.html',
  animations: [fadeIn],
})
export class RequestTrackerMessagesAndFilesComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private agentsClient: AgentsClient,
    private snackbarService: SnackbarService,
    private accountsClient: InvestmentAccountsClient,
    private accountUploadsClient: AccountUploadsClient,
  ) { }

  @ViewChild(FileDropperComponent) fileDropper!: FileDropperComponent;

  @ViewChild('fileUpload') fileUpload!: ElementRef<HTMLInputElement>;

  @Input() fileMessageData?: FileMessageUploadModalModel;
  @Input() notesAndFiles?: MessageAndFileModel[];
  @Input() filePrefix?: string;
  @Input() statusId?: number;
  @Output() refreshParentData = new EventEmitter<boolean>();

  advisorMessageFormGroup = this.formBuilder.group({
    //account: [null, [Validators.required]],
    account: [null],
    //recipient: [null, [Validators.required]], //hard coding, not required
    message: [null, [Validators.required]],
    files: [null, [Validators.required]],
  }) as AdvisorMessageFormGroup;

  messageSubscription?: Subscription;
  filesSubscription?: Subscription;
  accountsList?: Observable<AccountSearchListModel[]>;
  recipientsList: Recipient[] = recipientsListData;
  loggedInAdvisor?: AgentModel;
  advisorIds?: number[];
  selectedAccount: AccountSearchListModel | null = null;
  isLoading = false;
  submitCount = 0;
  requestTypeEnum = AccountMessageRequestTypeEnum;

  ngOnInit(): void {

    this.getData();
    this.messageSubscription = this.advisorMessageFormGroup.controls.message.valueChanges.subscribe(newValue => {
      if (newValue && typeof newValue === 'string') {
        this.validateFileAndMessageInputs(newValue);
      } else {
        this.validateFileAndMessageInputs('');
      }
    });
    this.filesSubscription = this.advisorMessageFormGroup.controls.files.valueChanges.subscribe(newValue => {
      if (<File[]>newValue) {
        this.validateFileAndMessageInputs(newValue as File[]);
      } else {
        this.validateFileAndMessageInputs([]);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['data']?.isFirstChange()) {
      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.messageSubscription?.unsubscribe();
    this.filesSubscription?.unsubscribe();
  }

  private getData(): void {

    const loggedInAdvisorApiCall: Observable<AgentModel> = this.agentsClient.getAgentSelf().pipe(catchError(error => {
      throw error;
    }));
    const advisorListApiCall: Observable<AgentModel[]> = this.agentsClient.getParentAgents(true).pipe(catchError(error => {
      throw error;
    }));
    const promises = {
      loggedInAdvisor: loggedInAdvisorApiCall,
      advisorList: advisorListApiCall
    };
    forkJoin(promises)
      .subscribe({
        next: (result) => {
          this.loggedInAdvisor = result.loggedInAdvisor;
          this.advisorIds = result.advisorList.map(advisor => {
            return advisor.agentID;
          });
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving information. Please try again later.', ToastClassEnum.warning);
        },
        complete: () => {
          if (this.advisorIds && this.advisorIds.length) {
            this.createObservableAccountList();
          }
          if (this.fileMessageData && this.fileMessageData.account) {
            const account = this.convertAccountToSearchListModel(this.fileMessageData.account);
            this.selectAccount(account);
            this.advisorMessageFormGroup.controls.account.patchValue(account);
          }
        },
      });
  }

  createObservableAccountList(): void {
    // In the template, the filteredAccountList will automatically be subscribed to and unsubscribed from.
    // This is because the async pipe used in the template takes care of both on our behalf.
    this.accountsList = this.advisorMessageFormGroup.controls.account.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      map(value => {
        // Ensure value is a string before it reaches the next operator.
        return value && typeof value === 'string' ? value : '';
      }),
      filter((value) => {
        // Ensures that the next operator is only reached if the string contains at least one character.
        return value.length > 0;
      }),
      switchMap((term) => {
        // The previous operator prevents searches from being made with an empty string, which would fail when the API call was made.
        return this.accountsClient.searchAcmAccountsForAgentIds(this.advisorIds, term);
      })
    );
  }

  private convertAccountToSearchListModel(account: Account): AccountSearchListModel | null {
    if (account && account.InvestmentAccountId) {
      return {
        accountNumber: account.AccountNumber ?? 'No Account Number',
        accountValue: account.Value ?? undefined,
        cashValue: account.CashValue ?? undefined,
        advisors: account.Advisors.map(a => {
          return { agentID: a.AdvisorId, crd: a.Crd, firstName: a.FirstName ?? '', lastName: a.LastName ?? '' };
        }),
        clients: account.Clients.map(c => `${c.FirstName} ${c.LastName}`).join(', '),
        custodian: account.Custodian ?? '',
        investmentAccountID: account.InvestmentAccountId,
        investmentAccountModel: account.Model ?? '',
        investmentAccountType: account.Type ?? ''
      };
    } else {
      return null;
    }
  }

  selectAccount(account: AccountSearchListModel | null): void {
    if (account && !account?.accountNumber) {
      account.accountNumber = 'No Account Number';
    }
    this.selectedAccount = account;
  }

  displayFn(account: AccountSearchListModel): string {
    if (account && account.investmentAccountID) {
      const custodian = account.custodian ?? '';
      const accountNumber = account.accountNumber ?? '';
      const clients = account.clients ?? '';
      return `${custodian} - ${accountNumber} - ${clients}`;
    } else {
      return '';
    }
  }

  /** Extracts files from a file upload event and adds them to the form post model.
     * The maximum size for a single file is 5MB or less.
     * The file type must be one of the following: pdf, jpg, jpeg, png.
     */
  uploadFiles(files: File[]): void {
    this.advisorMessageFormGroup.controls.files.patchValue(files);
  }

  private validateFileAndMessageInputs(value: string | File[]): void {
    let isUpdated = false;
    if (typeof value === 'string') {
      if (value.length) {
        this.advisorMessageFormGroup.controls.files.clearValidators();
        isUpdated = true;
      }
    } else {
      if (value.length > 0) {
        this.advisorMessageFormGroup.controls.message.clearValidators(
        );
        isUpdated = true;
      }
    }

    if (!isUpdated) {
      this.advisorMessageFormGroup.controls.files.addValidators(Validators.required);
      this.advisorMessageFormGroup.controls.message.addValidators(Validators.required);
    }
    // The "emitEvent: false" statement is used to avoid a circular change detection cycle.
    this.advisorMessageFormGroup.controls.files.updateValueAndValidity({ onlySelf: false, emitEvent: false });
    this.advisorMessageFormGroup.controls.message.updateValueAndValidity({ onlySelf: false, emitEvent: false });
  }

  private createPostModel(): AccountMessagePostModel {

    const accountMessageModel: AccountMessageModel = {
      //recipientID: this.advisorMessageFormGroup.value.recipient.recipientId,//
      recipientID: 1, //hard coding Ops from recipientsListData.
      agentID: this.loggedInAdvisor?.agentID ?? 0,
      accountID: this.advisorMessageFormGroup.value.account?.investmentAccountID ?? 0,
      notes: this.advisorMessageFormGroup.value.message,
      onBehalfOfRequest: this.fileMessageData?.onBehalfOfRequest,
    };

    const advisorMessagePostModel: AccountMessagePostModel = {
      accountMessageModel: accountMessageModel,
      files: this.createFileParameter(this.advisorMessageFormGroup.value.files)
    };

    return advisorMessagePostModel;
  }

  private createPlanningDeskPostModel(): PlanningDeskMessagePostModel {

    const accountMessageModel: PlanningDeskMessageModel = {
      //recipientID: this.advisorMessageFormGroup.value.recipient.recipientId,//
      recipientID: 1, //hard coding Ops from recipientsListData.
      agentID: this.loggedInAdvisor?.agentID ?? 0,
      notes: this.advisorMessageFormGroup.value.message,
      portfolioAnalysisRequestID: this.fileMessageData?.onBehalfOfRequest?.requestId ?? 0,
    };

    const advisorMessagePostModel: PlanningDeskMessagePostModel = {
      accountMessageModel: accountMessageModel,
      files: this.createFileParameter(this.advisorMessageFormGroup.value.files)
    };

    return advisorMessagePostModel;
  }

  /** Transforms an array of type File into an array of type FileParameter. */
  private createFileParameter(files: File[]): FileParameter[] {
    return files.map(file => {
      return {
        fileName: file.name,
        data: file,
      };
    });
  }

  /** Submits the form data if it is valid. */
  onSubmit(): void {
    if (this.advisorMessageFormGroup.invalid) {
      this.advisorMessageFormGroup.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    if (this.fileMessageData?.onBehalfOfRequest?.requestType === this.requestTypeEnum.PlanningDesk) {

      const planningDeskMessageModel = this.createPlanningDeskPostModel();

      this.accountUploadsClient.postPlanningDeskAccountMessage(planningDeskMessageModel.accountMessageModel, planningDeskMessageModel.files).subscribe({
        error: () => {
          this.submitCount++;
          // for work around on sometimes the first request failing submitting to the crm.
          if (this.submitCount < 2) {
            console.log('received error, retrying in 2 seconds');
            setTimeout(() => {
              this.onSubmit();
            }, 2000);
          } else {
            this.isLoading = false;
            this.submitCount = 0;
          }
        },
        complete: () => {
          this.isLoading = false;
          this.advisorMessageFormGroup.controls.files.setValue([]);
          this.advisorMessageFormGroup.controls.message.reset();
          this.isLoading = false;
          this.refreshParentData.next(true);
        }
      });

    } else {

      const accountMessageModel = this.createPostModel();

      this.accountUploadsClient.postAccountMessage(accountMessageModel.accountMessageModel, accountMessageModel.files).subscribe({
        error: () => {
          this.submitCount++;
          // for work around on sometimes the first request failing submitting to the crm.
          if (this.submitCount < 2) {
            console.log('received error, retrying in 2 seconds');
            setTimeout(() => {
              this.onSubmit();
            }, 2000);
          } else {
            this.isLoading = false;
            this.submitCount = 0;
          }
        },
        complete: () => {
          this.fileDropper.removeAllFiles();
          this.advisorMessageFormGroup.controls.files.setValue([]);
          this.advisorMessageFormGroup.controls.message.reset();
          this.isLoading = false;
          this.refreshParentData.next(true);
        }
      });
    }
  }
}
