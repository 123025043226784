import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { AccountUploadsClient, AgentModel, AgentsClient, TaskFilesListModel } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { SidenavConfig } from 'src/app/shared/models/generic.models';
import { FilterCheckbox } from '../../../request-tracker/models/request-tracker-models';
import { FileMessageUploadModalComponent } from '../../modals/file-message-upload-modal/file-message-upload-modal.component';
import { FileMessagesFilter } from '../../models/files-messages.model';
import { Permission } from 'src/app/core/clients/generated/client';
import { FilesMessagesListComponent } from '../../component/files-messages-list/files-messages-list.component';
import { SavedFiltersService } from 'src/app/shared/services/saved-filters.service';
import { PermissionsService } from 'src/app/core/auth/permissions.service';

@Component({
  animations: [fadeIn],
  selector: 'app-files-messages-page',
  templateUrl: './files-messages-page.component.html',
})
export class FilesMessagesPageComponent implements OnInit {
  constructor(
    private accountUploadsApiService: AccountUploadsClient,
    private activatedRoute: ActivatedRoute,
    private agentsApiService: AgentsClient,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private savedFiltersService: SavedFiltersService,
    private _permissionService: PermissionsService
  ) { }
  @ViewChild(FilesMessagesListComponent) private fileList?: FilesMessagesListComponent;
  @ViewChild('advisorRadioGroupContainer') advisorRadioGroupContainer!: ElementRef<HTMLInputElement>;
  accountUploads: TaskFilesListModel[] = [];
  advisors: AgentModel[] = [];
  disabled = false;
  filteredUploads: TaskFilesListModel[] = [];
  filteredAdvisors: FilterCheckbox[] = [];
  isAcmStaff = false;
  isLoading = false;
  perm = Permission;
  searchTerm = '';
  advisorSearchTerm = '';
  selectedRadioAdvisorId = 0;
  sidenavConfig: SidenavConfig = {
    Mode: 'side',
    IsOpened: true
  };
  tableFilter = new FileMessagesFilter();

  ngOnInit(): void {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');

    this.isAcmStaff = this._permissionService.hasOnePermission(Permission.AdminViewAllAdvisors);

    if (!this.isAcmStaff) {
      this.getAdvisors()
        .subscribe({
          next: results => {
            this.advisors = this.initAdvisors(results.selfAdvisor, results.parentAdvisors);

            this.initSelectAdvisors(results.selfAdvisor, results.parentAdvisors);

            this.getAccountUploads(this.advisors);
          }
        });
    } else {
      this.getAllAdvisors().subscribe({
        next: results => {
          this.advisors = results.allAdvisors;
          this.initSelectAdvisors(null, results.allAdvisors);
          this.isLoading = false;
          return this.advisors;
        }, error: () => {
          this.snackbarService.openSnackbar('Error retrieving Accounts.', ToastClassEnum.warning);
        }
      });
    }
  }

  compareAdvisors(a: AgentModel, b: AgentModel): boolean {
    return a && b && a.agentID === b.agentID;
  }

  /**Use radio buttons when selecting from 50+ advisors to prevent excessive load time
   * and allow picking one advisor at a time.
   * @param filteredAdvisorId model of selected advisor
  */
  radioAdvisorChecked(filteredAdvisorId: string | number): void {
    this.selectRadioOption(filteredAdvisorId);
    this.loadSingleAdvisorUploads();
  }

  selectRadioOption(filteredAdvisorId: string | number): void {
    if (this.filteredAdvisors.some(a => a.Value === this.selectedRadioAdvisorId)) {
      const oldSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
      oldSelectedRadioAdvisor.Checked = false;
    }

    this.selectedRadioAdvisorId = Number(filteredAdvisorId);
    const newSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
    newSelectedRadioAdvisor.Checked = true;
  }

  loadSingleAdvisorUploads(): void {
    this.isLoading = true;
    this.accountUploadsApiService.getAccountUploadsByAgentIds([this.selectedRadioAdvisorId])
      .subscribe({
        next: taskFilesListModel => {
          this.accountUploads = taskFilesListModel;
          this.filterUploads();
        }, error: () => {
          this.snackbarService.openSnackbar('Error retrieving account uploads', ToastClassEnum.warning);
        }
      })
      .add(() => {
        this.isLoading = false;
        if (this.fileList) this.fileList.isLoading = false;
      });
  }

  getAccountUploads(advisors: AgentModel[]): void {
    this.isLoading = true;
    this.accountUploadsApiService.getAccountUploadsByAgentIds(advisors.map(a => a.agentID))
      .subscribe({
        next: taskFilesListModel => {
          this.accountUploads = taskFilesListModel;
          this.filterUploads();
        }, error: () => {
          this.snackbarService.openSnackbar('Error retrieving account uploads', ToastClassEnum.warning);
        }
      })
      .add(() => {
        this.isLoading = false;
        if (this.fileList) this.fileList.isLoading = false;
      });
  }

  filterUploads(): void {

    //const activeAgentIdFilters: FilterCheckbox[] = this.tableFilter.Advisors.filter(t => t.Checked);
    const activeAgentIdFilters: FilterCheckbox[] = this.filteredAdvisors.filter(t => t.Checked);

    //save selected advisorIds to local storage
    const selectedAdvisorIds = activeAgentIdFilters.map(adv => adv.Value) as [];
    this.savedFiltersService.setSelectedAdvisors(selectedAdvisorIds);

    this.filteredUploads = this.accountUploads.filter(a => {
      const accountAgentIds = a.advisors.map(adv => adv.agentID);
      const accountContainsAgent = accountAgentIds?.some(id => activeAgentIdFilters.some(a => a.Value === id));
      return accountContainsAgent && hasAll(a, this.searchTerm);
    });
  }

  getAdvisors(): Observable<{ parentAdvisors: AgentModel[]; selfAdvisor: AgentModel | null; }> {
    return forkJoin({
      parentAdvisors: this.agentsApiService.getParentAgents(false),
      selfAdvisor: this.agentsApiService.getAgentSelf()
    });
  }

  getAllAdvisors(): Observable<{ allAdvisors: AgentModel[];}> {
    return forkJoin({
      allAdvisors: this.agentsApiService.getAgents(),
    });
  }

  initAdvisors(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): AgentModel[] {
    // combine parent advisors and self advisor
    let advisors: AgentModel[] = parentAdvisors;
    if (selfAdvisor !== null) {
      advisors = parentAdvisors.concat([selfAdvisor]);
    }
    return advisors;
  }

  initSelectAdvisors(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): void {

    // for preselect parent and self advisors if the user hasn't any saved in local storage yet
    const preSelectedAdvisors = parentAdvisors.concat([selfAdvisor as AgentModel]);
    const savedAdvisors = this.savedFiltersService.getSelectedAdvisors();

    parentAdvisors.forEach(a => {
      this.tableFilter.Advisors.push({
        Name: a.firstName + ' ' + a.lastName,
        Value: a.agentID,
        Checked: this.isAcmStaff ? false : savedAdvisors.length > 0 ? savedAdvisors.some(x => x === a.agentID) : preSelectedAdvisors.some(x => x.agentID === a.agentID),
      });
    });
    if (selfAdvisor) {
      this.tableFilter.Advisors.unshift({
        Name: selfAdvisor.firstName + ' ' + selfAdvisor.lastName,
        Value: selfAdvisor.agentID,
        Checked: this.isAcmStaff ? false : savedAdvisors.length > 0 ? savedAdvisors.some(a => a === selfAdvisor.agentID) : preSelectedAdvisors.some(a => a.agentID === selfAdvisor.agentID),
      });
    }

    this.selectedRadioAdvisorId = this.tableFilter.Advisors[0].Value as number;
    this.filterAdvisorList();

    //set index for radio list if this.isArcAdvisor is true
    if (this.isAcmStaff === true) {
      //load the the first saved advisorId if any else load the first advisor
      const agentId = savedAdvisors.length > 0 ? savedAdvisors[0] : this.tableFilter.Advisors[0].Value;
      this.selectRadioOption(agentId);
      this.loadSingleAdvisorUploads();
    }
  }

  filterAdvisorList(): void {
    const results = this.tableFilter.Advisors.filter(a => {
      return hasAll(a, this.advisorSearchTerm);
    });
    this.filteredAdvisors = results;

    if (this.selectedRadioAdvisorId != null) {
      setTimeout(() =>{
        this.scrollToOption(this.selectedRadioAdvisorId.toString());
      }, 1200);
    }
  }

  scrollToOption(optionValue: string): void {
    if (this.advisorRadioGroupContainer){
      const selectedRadioButton = this.advisorRadioGroupContainer.nativeElement.querySelector(
        `mat-radio-button[id="advisor-${optionValue}"]`
      );
      if (selectedRadioButton != null) {
        selectedRadioButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  openNewMessageModal(): void {
    const ref = this.dialog.open<FileMessageUploadModalComponent>(FileMessageUploadModalComponent);

    ref.afterClosed().subscribe(result => {
      if (result) {
        // successful upload
        this.searchTerm = '';
        this.getAccountUploads(this.advisors);
      }
    });
  }

  selectAdvisors(): void {
    this.filterUploads();
  }
}
